<template>
    <page-title
        title="学校・イベント一覧"
        icon="bi-mortarboard-fill"
    >
        <button-go-create
            :to="{name: 'SchoolAdd'}"
        ></button-go-create>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>学校名 / イベント名</label>
                <form-input
                    v-model="condition.school_name"
                ></form-input>
            </div>
            <div class="col-6">
                <label>学校担当者</label>
                <form-select-search
                    v-model="condition.photographer_id"
                    :options="options_employee"
                    empty_option="-- 全員 --"
                ></form-select-search>
            </div>
            <div class="col-3">
                <button-search
                    @click="search()"
                    :disabled="loading"
                ></button-search>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="schools.length">
                <table class="table  table-striped mb-4">
                    <thead>
                        <tr class="table-primary">
                            <th class="col-6">学校名</th>
                            <th class="col-3">略称</th>
                            <th class="col-3">担当者</th>
                            <th class="col-6">所在地</th>
                            <th class="col-6"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="school in schools" :key="school">
                            <tr>
                                <td>{{ school.school_name }}</td>
                                <td>{{ school.shortened_name }}</td>
                                <td>{{ school.employee.photographer_name }}</td>
                                <td>{{ school.full_address }}</td>
                                <td class="">
                                    <button-go-update
                                        :to="{name: 'SchoolEdit', params:{id: school.school_id}}"
                                    ></button-go-update>
                                    <button-go-view
                                        class="ms-5"
                                        :to="{name: 'Event', params:{id: school.school_id}}"
                                        text="行事マスタ"
                                        icon="bi-calendar-event"
                                    ></button-go-view>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <paginator :meta="paginator" @move="search"></paginator>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import School from '@/models/entities/school';
import ButtonGoView from '@/components/buttons/ButtonGoView';
import FormInput from '@/components/forms/FormInput';
import InlineLoader from '@/components/tools/InlineLoader';
import Paginator from '@/components/tools/Paginator';
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import Employee from '@/models/entities/employee';
import FormSelectSearch from '@/components/forms/FormSelectSearch';


export default {
    name: 'SchoolList',
    components: {
        PageTitle,
        ButtonGoCreate,
        ButtonGoUpdate,
        ButtonGoView,
        ButtonSearch,
        FormInput,
        FormSelectSearch,
        AlertNoRecord,
        InlineLoader,
        Paginator,
    },
    data() {
        return {
            //学校
            schools: [],

            // 検索
            loading: 0,
            condition: {
                school_name: null,
                photographer_id: null,
                page: 1,
            },
            paginator: {},

            // 検索選択肢
            options_employee: [],
        }
    },
    mounted() {
        this.fetchAllEmployees();
    },
    methods: {
        // 検索
        search(page = 1) {
            this.loading++;
            this.condition.page = page;

            // 表示データ初期化
            this.schools.splice(0);

            this.$http.get('/schools', {
                params: this.condition
            })
            .then(response => {

                for (let row of response.data.data) {
                    this.schools.push(new School(row));
                }

                // ページネーション用
                this.paginator = response.data.meta;
                // 検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'SchoolList',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading--;
            })
        },
        // 全ての社員取得
        fetchAllEmployees() {
            this.loading++;

            this.$http.get('/employees/all')
            .then(response => {
                for (let row of response.data) {
                    let employee = new Employee(row);
                    this.options_employee.push({key: employee.photographer_id, label: employee.photographer_name});
                }

                this.setConditions();
            })
            .finally(() => {
                this.loading--;
            });
        },
        setConditions () {
            this.condition = this.$store.getters['condition/merge']('SchoolList', this.condition);
            this.search();
        },
    }
}
</script>

<style scoped>

</style>

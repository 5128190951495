<template>
    <router-link :to=to class="btn btn-primary" :class="size">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </router-link>
</template>


<script>
export default {
    name: 'ButtonGoView',
    props: {
        text: {
            type: String,
            default: '詳細',
        },
        icon: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'button',
        },
        to: {
            type: Object,
            default: () => {
                return {
                    name: null,
                    params: null,
                };
            }
        },
        size: {
            type: String,
            default: ''
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
